import { render, staticRenderFns } from "./AreaIntro.vue?vue&type=template&id=02a51fda&scoped=true&"
import script from "./AreaIntro.vue?vue&type=script&lang=js&"
export * from "./AreaIntro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a51fda",
  null
  
)

export default component.exports