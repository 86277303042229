<template>
<div class="container">
    <div class="text-justify">
        <h2>LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN</h2>
        <p>
            Tomando en consideración la finalidad y el objeto que pretende
            salvaguardar la Ley Federal para la Prevención e Identificación de
            Operaciones con Recurso de Procedencia Ilícita, también conocida como
            Ley Antilavado, se debe de contar con especial cuidado al momento de su
            cumplimiento, ya que, las infracciones a las cuales se pueden hacer
            acreedores los sujetos obligados a ésta pueden llegar a ser por
            cantidades sumamente cuantiosas.
            <br /><br />
            En ese sentido, en Aurum Corporativo Legal, brindamos una asesoría y
            apoyo completo en el tema, a efecto de guiar paso a paso a nuestros
            clientes, con la intención de que puedan regularizarse y estén en
            posibilidad de dar cabal cumplimiento a las obligaciones a las cuales se
            encuentran sujetos, así como conocer los alcances que éstos implican.
        </p>

        <ul style="list-style-type: upper-alpha">
            <li>
                Elaboración de los manuales o lineamientos internos de los sujetos
                obligados.
            </li>

            <li>Asesoría en cuanto a la presentación de Avisos Mensuales.</li>

            <li>
                Asesoría para la elaboración de los documentos que identifiquen a los
                usuarios.
            </li>

            <li>
                Capacitaciones a las personas morales y físicas, o en su caso al
                personal designado, respecto al debido cumplimiento de las
                obligaciones impuestas en la LFPIORPI.
            </li>
            <li>
                Consultoría para la implementación de las medidas necesarias a efecto
                de dar cumplimiento con las obligaciones.
            </li>
            <li>
                Elaboración de diagnósticos y auditorías internas en cuanto al
                cumplimiento de las disposiciones de la normatividad aplicable.
            </li>
            <li>
                Elaboración de consultas y aclaraciones frente a la Autoridad
                competente.
            </li>
            <li>
                Apoyo en atención a cartas invitación emitidas por la Autoridad
                competente.
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: "Area5",
};
</script>

<style scoped>
</style>
