<template>
<div class="container text-justify">
    <div>
        <h2>DERECHO Y GOBIERNO CORPORATIVO</h2>
        <p>
            Para logar un crecimiento ordenado y alcanzar las proyecciones de corto,
            mediano y largo plazo, resulta necesario para una empresa contar con una
            debida organización y práctica corporativa.
        </p>
        <p>
            Atendiendo a ello, nuestro equipo especializado en el área de Derecho
            Corporativo asesora a nuestros clientes desde la constitución de una
            Sociedad, mediante la adecuación de los estatutos legales a las
            necesidades y exigencias que cada giro le ordena, así como al
            cumplimiento de las obligaciones que les imponen las normas a lo largo
            de su operación.
        </p>
        <p>
            Si es el caso, para cada proyecto se trabaja en conjunto con las
            distintas áreas de especialidad de nuestro equipo, como son las
            relativas al Área Fiscal y de Seguridad Social, con la finalidad de
            satisfacer cabalmente las necesidades de nuestros clientes, brindando
            así un servicio integral.
        </p>
        <h3 class="w-100">SERVICIOS CORPORATIVOS</h3>
        <ul>
            <li>Constitución de Sociedades.</li>
            <li>Registro de Inversión Extranjera.</li>
            <li>Elaboración de Actas de Asamblea.</li>
            <li>Preparación y elaboración de libros corporativos.</li>
            <li>Fusiones, Escisiones y Adquisiciones.</li>
            <li>Gobierno Corporativo.</li>
            <li>Institucionalización de Empresas Familiares.</li>
            <li>Reestructuraciones corporativas.</li>
            <li>Elaboración, análisis y revisión de Contratos.</li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: "Area4",
};
</script>

<style scoped>
</style>
