<template>
<!-- content begin -->
<div class="no-bottom no-top" id="content">
    <div id="top"></div>
    <!-- section begin -->
    <section id="subheader" class="text-light" data-stellar-background-ratio=".2" style="background-image: url('../images/background/areas.jpg'); background-size: cover;">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <div class="spacer-single"></div>
                        <h1>Áreas de práctica</h1>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- section close -->
    <router-view class="mt-5"></router-view>

    <section class="pt-4 mt-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <blockquote>Lee más acerca de nuestros servicios
                    </blockquote>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(1)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-law-alt-3 bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>CONSULTORÍA Y LITIGIO FISCAL</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(2)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-law-alt-3 bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>CONSULTORÍA Y LITIGIO ADMINISTRATIVO</h4>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(3)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-bank-alt bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>DERECHO Y GOBIERNO CORPORATIVO</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(4)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-handshake-deal bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>DERECHO EN COMERCIO EXTERIOR</h4>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(5)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-law-order bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN </h4>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(6)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-businesswoman bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>DERECHO EN SEGURIDAD SOCIAL</h4>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(7)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-company bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>CORPORATE COMPLIANCE</h4>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb30 p-2" @click="toSpecificArea(8)">
                    <div class="shadow">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <i class="icofont-presentation-alt bg-color text-light"></i>
                            <div class="fb-text">
                                <h4>DUE DILLIGENCE</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
<!-- content close -->
</template>

<script>
export default {
    name: "PracticeAreas",
    methods: {
        toSpecificArea(id) {
            this.$router.push({
                path: `/areas/${id}`
            })
        }
    }
}
</script>

<style scoped>
.f-box.f-icon-left {
    align-items: center;
}

.f-box.f-icon-rounded i:hover {
    width: 100px;
    height: 100px;
}

.f-box i:hover {
    font-size: 50px;
}

.f-box:hover {
    cursor: pointer;
}
</style>
