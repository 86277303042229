import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Team from '../views/Team.vue'
import Blog from '../views/Blog.vue'
import Contact from '../views/Contact.vue'
import PracticeAreas from "../views/PracticeAreas";

//Areas
import Area1 from "../views/areas/Area1";
import Area2 from "../views/areas/Area2";
import Area3 from "../views/areas/Area3";
import Area4 from "../views/areas/Area4";
import Area5 from "../views/areas/Area5";
import Area6 from "../views/areas/Area6";
import Area7 from "../views/areas/Area7";
import Area8 from "../views/areas/Area8";
import AreaIntro from "../views/areas/AreaIntro";

import PdfList from "../views/PdfList";

Vue.use(VueRouter)

let areasPages = {
    path: "/areas",
    component: PracticeAreas,
    name: "PracticeAreas",
    children: [
        {
            path: '/areas/1',
            name: 'Area1',
            component: Area1
        },
        {
            path: '/areas/2',
            name: 'Area2',
            component: Area2
        },
        {
            path: '/areas/3',
            name: 'Area3',
            component: Area3
        },
        {
            path: '/areas/4',
            name: 'Area4',
            component: Area4
        },
        {
            path: '/areas/5',
            name: 'Area5',
            component: Area5
        },
        {
            path: '/areas/6',
            name: 'Area6',
            component: Area6
        },
        {
            path: '/areas/7',
            name: 'Area7',
            component: Area7
        }, {
            path: '/areas/8',
            name: 'Area8',
            component: Area8
        }, {
            path: '/areas/intro',
            name: 'AreaIntro',
            component: AreaIntro
        },
    ]
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/team',
        name: 'Team',
        component: Team
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/article',
        name: 'Article',
        component: PdfList,
    },
    areasPages
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router