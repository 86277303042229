<template>
<footer>
    <div class="container">
        <div class="row justify-content-between">

            <div class="col-12 col-lg-3 ">
                <div class="bg-sc row d-flex justify-content-left" style="margin-top: -20px;">
                    <img alt="" class="img-fluid mb20" src="images/logos/LOGOBLANCO.svg" width="250px">
                </div>
                <div class="sm-sc row fl-sm mb-5" style="margin-top: -20px;">
                    <a href="index.html"><img alt="" class="img-fluid mb20" src="images/logos/LOGOBLANCO.svg" width="250px"></a>
                </div>
                <div>
                    <p class="bg-sc">Conoce nuestra política de privacidad.</p>
                    <div class="sm-sc">
                        <div class="row d-flex justify-content-center text-center" style="margin-top: -3em;">
                            <p class="bold" style="font-size: 1.3em;">Conoce nuestra política de privacidad</p>
                        </div>
                    </div>
                    <div class="spacer-10"></div>

                    <div class="row d-flex justify-content-left mobile-justify-content-center" style="margin: 0;">
                        <a data-toggle="modal" data-target=".bd-example-modal-xl" class="btn-custom btn-white text-dark">Conocer</a>
                        <terms-modal></terms-modal>
                    </div>

                </div>

            </div>

            <div class="col-md-4 mb-2 mt-m">
                <h3 class="id-color mb20 mtm">Visítanos</h3>
                <google-map :center="{lat:28.647651769676422, lng:-106.09756569952292}" :footer="true"></google-map>
            </div>

            <div class="col-lg-4 mt-m">
                <div class="widget">
                    <h3 class="id-color mtm">Contáctanos</h3>

                    <address class="s1">
                        <br>
                        <div class="row">
                            <div class="col-1"><i class="id-color fa fa-map-marker fa-lg"></i></div>
                            <div class="col-10">Calle Pedro de Villa Sur #208-A, San Felipe, Chihuahua, Chihuahua</div>
                        </div>
                        <div class="row">
                            <div class="col-1"><i class="id-color fa fa-phone fa-lg"></i></div>
                            <div class="col-10">614 443 1518</div>
                        </div>
                        <div class="row">
                            <div class="col-1"><i class="id-color fa fa-envelope-o fa-lg"></i></div>
                            <div class="col-10"><a href="mailto:contacto@aurumcorp.com.mx<">contacto@aurumcorp.com.mx</a></div>
                        </div>

                        <div class="row d-flex justify-content-center mt-4">
                            <span>
                                <a href="https://www.facebook.com/Aurum-Corporativo-Legal-103115064711077"><i class="fa fa-facebook"></i></a>
                                <a href="https://www.instagram.com/aurumcorporativolegal/?hl=es-la"><i class="fa fa-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/aurum-corporativo-legal/"><i class="fa fa-linkedin"></i></a>
                            </span>
                        </div>
                    </address>
                </div>
            </div>

        </div>
    </div>
    <div class="subfooter">
        <div class="container">

        </div>
    </div>
</footer>
</template>

<script>
import GoogleMap from "../components/GoogleMap";
import TermsModal from "./TermsModal";

export default {
    name: 'BotFooter',
    components: {
        GoogleMap,
        TermsModal
    },
}
</script>

<style scoped>
.id-color {
    color: white !important;
}

.fa {
    font-size: 1.5em;
}

.sm-sc {
    display: none !important;
}

.bg-sc {
    display: block !important;
}

a.btn-custom {
    padding: 7px 20px;
}

@media (max-width: 600px) {
    .reverse-cols-mobile {
        display: flex;
        flex-direction: column-reverse;
    }

    .bg-sc {
        display: none !important;
    }

    .sm-sc {
        display: block !important;
    }

    .mobile-justify-content-center {
        justify-content: center !important;
    }

    .fl-sm {
        display: flex !important;
        justify-content: center !important;
    }

    .mt-m {
        margin-top: 2em;
    }
}
</style>
