<template>
<div class="container text-justify">
    <div>
        <h2>DERECHO EN COMERCIO EXTERIOR</h2>
        <p>
            En Aurum Corporativo Legal, conocemos la gran necesidad que tienen las
            empresas con un enfoque internacional de contar con una asesoría legal
            especializada a efecto de conocer y dar cumplimiento a la normatividad
            que les es aplicable.
        </p>
        <p>
            En ese sentido, nuestros abogados bilingües del área de comercio
            exterior, brindan asesoría y soporte legal a todas aquellas empresas que
            cuentan con un corporativo en el extranjero, o bien, que sus clientes y
            proveedores se ubiquen a su vez fuera del territorio nacional; dicho
            soporte legal puede brindarse mediante la asesoría en la tramitación de
            diversas solicitudes y permisos, elaboración de contratos, obtención e
            implementación de la Certificación de IVA e IEPS, elaboración de
            opiniones jurídicas respecto a la interpretación de las diversas normas
            aplicables, entre otros.
        </p>
        <p>
            Nuestro objetivo es que los trámites legales no representen un obstáculo
            en las operaciones transnacionales realizadas cotidianamente por
            nuestros clientes.
        </p>
        <h3 class="w-100">ASESORÍA JURÍDICA EN MATERIA DE COMERCIO EXTERIOR</h3>
        <ul>
            <li>
                Asesoría para la obtención de la Certificación IVA e IEPS, en las
                modalidades “A”, “AA” Y “AAA”.
            </li>
            <li>
                Asesoría y elaboración de opiniones jurídicas respecto a la
                normatividad aplicable en materia de comercio exterior.
            </li>
            <li>
                Apoyo legal en diversos trámites, solicitudes y permisos en materia de
                comercio exterior frente a la Autoridad aduanera.
            </li>
            <li>
                Asesoría en la aplicación de los diversos beneficios de los decretos o
                programas de beneficios en materia de comercio exterior.
            </li>
        </ul>

        <h3 class="w-100">DEFENSA LEGAL EN MATERIA DE COMERCIO EXTERIOR. </h3>
        <ul>
            <li>
                Acompañamiento legal durante las facultades de comprobación por parte de la Autoridad aduanera.
            </li>
            <li>
                Defensa legal en contra de sanciones, multas y créditos fiscales impuestos por la Autoridad aduanera.
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: "Area4",
};
</script>

<style scoped>
ul {
    list-style-type: upper-alpha;
}
</style>
