<template>
<div>
    <div id="top"></div>
    <section id="subheader" class="text-light" data-stellar-background-ratio=".2" style="background-image: url('images/background/blog.png'); background-size: cover;">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Artículos</h1>
                        <p>Explora nuestros articulos</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- section close -->
    <div class="container no-bottom pt-3">
        <div class="row">
            <div v-if="activePdf" class="col-md-8">
                <pdf-viewer :pdfSrc="activePdf"></pdf-viewer>
            </div>
            <div id="sidebar" class="col-md-4">
                <h4>Otros artículos</h4>
                <div class="small-border"></div>
                <div v-for="year in articles" :key="year.name">
                    <div @click="year.show = !year.show">
                        <span class="date">{{year.name}}</span>
                    </div>
                    <div v-if="year.show">
                        <ul>
                            <li v-for="article in year.article" :key="article"><div @click="changeArticle(year.name,article)">{{article}}</div></li>
                        </ul>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PdfViewer from '../components/PdfViewer.vue'
export default {
    name: "PdfList",
    components: { PdfViewer },
    data() {
        return {
            articles: {
                year1: {
                    name: "2020",
                    article: ["12-2020 Iniciativa. Prohibicion Outsorcing.pdf"],
                    show: true,
                },
                year2: {
                    name: "2021",
                    article: [
                    "04-21 Outsourcing. Reforma.pdf",
                    "06-12-21 Boletin. Servicios Especializados  (3).pdf",
                    "07-16-21 Boletin. ANAM.pdf",
                    "07-21 AGENCIA NACIONAL DE ADUANAS EN MÉXICO. NUEVA AUTORIDAD ADUANERA (5) (1).pdf",
                    "12-21 Cuentas incobrables.pdf",
                    ],
                    show: true,
                },
                year3: {
                    name: "2022",
                    article: [
                    "02-22 Boletín-Obligaciones Coporativas.pdf",
                    ],
                    show: true,
                },
            },
            activePdf: "pdf/2020/12-2020 Iniciativa. Prohibicion Outsorcing.pdf",
        }
    },
    methods: {
        changeArticle(year,article){
            this.activePdf = "pdf/"+year+"/"+article;
            console.log(this.activePdf)
        },
    },
    mounted() {
        if(this.$route.query.year){
            if(this.$route.query.year == '2020'){
                this.activePdf = "pdf/2020/12-2020 Iniciativa. Prohibicion Outsorcing.pdf"
            } else if(this.$route.query.year == '2021'){
                this.activePdf = "pdf/2021/12-21 Cuentas incobrables.pdf"
            } else if(this.$route.query.year == '2022'){
                this.activePdf = "pdf/2022/02-22 Boletín-Obligaciones Coporativas.pdf"
            }
        }
    }
}
</script>

<style scoped>
h4 {
    margin-top: 0;
    letter-spacing: normal;
}
.container {
    margin-left: 0;
    min-width: 100%;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
li {
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
li:hover {
    cursor: pointer;
}
li div {
    padding-left: 60px;
    display: block;
    text-decoration: none;
    color: #111111;
}
.date {
    cursor: pointer;
    background: #333;
    color: #fff;
    display: inline-block;
    padding: 0px 5px 0px 5px;
    font-size: 20px;
}
.small-border {
    height: 2px;
    width: 30px;
    background: #fff;
    margin: 0px 0 20px 0;
    display: block;
}
</style>